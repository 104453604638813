import React from 'react'
import '../.././bootstrap.min.css'
import './menu.js'
import '../.././main.css'
import cookie from 'react-cookies'
import HISTORY from '../../History'
// import { Steps } from "intro.js-react"
import Header from './Header';
import { Link } from 'react-router-dom'
import HeaderStore from "./HeaderStore"
import ReactTooltip from 'react-tooltip'
import HeaderService from "./HeaderService"
import LoadingBar from 'react-top-loading-bar'
import { LOCALES } from '../../Providers/i18n'
import LeavingWeb from '../../utils/LeavingWeb'
import LangSwitcher from '../Header/LangSwitcher'
import { AppContext } from '../../Providers/context'
import SwitchTextSize from 'react-font-size-resizer'
import translate from '../../Providers/i18n/Translate'
import CONSTANTS from '../../Customer/constants/dashboard-constants'
import Settings from '../../Customer/dashboard/settings/settings-component'
import NotificationsComponent from '../../Customer/dashboard/notifications/notifications-component'

var local
class Nav extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      response: '',
      notificationBg: false,
      accBg: false,
      serviceEditClick: false,
      isLogout: false,
      isLoading: false,
      language: '',
      payload: localStorage.dashboard && JSON.parse(localStorage.getItem('dashboard')).payload,
      userType: localStorage.getItem('userType'),
      ISUAEPASS: '',
      showLeavePopup: false,
      urlAddress: "",
      query: "",
      stepsEnabled: false,
      initialStep: 0
    }
    this.onChange = this.onChange.bind(this)
    this.getTooltips = this.getTooltips.bind(this)
    this.prepareWidgets = this.prepareWidgets.bind(this)
    this.handleAddClick = this.handleAddClick.bind(this)
    this.getPayload = this.getPayload.bind(this)
    this.myTheme = this.myTheme.bind(this)
    this.enter = this.enter.bind(this)
    this.showLeavePopup = this.showLeavePopup.bind(this);
    this.hideLeavePopup = this.hideLeavePopup.bind(this);
  }
  // isLogedIn() {
  //   return localStorage.getItem('accessToken')
  // }
  componentWillUnmount() {
    HeaderStore.removeChangeListener(this.onChange)
  }

  onChange() {
    this.LoadingBar && this.LoadingBar.complete()
    this.setState(this.prepareWidgets(HeaderStore.response))
  }

  componentDidMount() {
    local = '?local=' + this.context.state.siteLang
    if (this.context.state.siteLang === LOCALES.ENGLISH) {
      require('../.././main.css');
      require('../.././black_theme.css');
      require('../.././red_theme.css');
      require('../.././green_theme.css');
      require('../.././mediascreen.css');
      // require('intro.js/introjs.css');
    } else {
      require('../.././arabic.css');
      require('../.././black_theme.css');
      require('../.././red_theme.css');
      require('../.././green_theme.css');
      require('../.././mediascreen.css');
      // require('intro.js/introjs.css');
      // require('intro.js/introjs-rtl.css');
    }
    this.LoadingBar && this.LoadingBar.continuousStart()
    HeaderStore.addChangeListener(this.onChange)
    HeaderService.dashboardRequest(local)
  }

  getDashboardPayload() {
    return this.state.response
  }

  prepareWidgets(response) {
    if (response && response.success) {
      if (!this.isEservicesRoute() && this.settings) {
        this.settings.initSettings(response.payload.settings)
      }
      localStorage.setItem("dashboard", JSON.stringify(response))
      this.setState({ payload: response.payload, response: response, isLoading: false })
    } else {
      this.setState({ payload: null })
    }
  }

  handleAddClick() {
    if (this.isLogedIn()) {
      this.setState({
        notificationBg: !this.state.notificationBg,
        notificationBtnClick: !this.state.notificationBtnClick
      })
    }
  }

  getPayload() {
    return this.state.payload
  }

  logout = () => {
    var local = localStorage.getItem('siteLang');
    var username = localStorage.getItem('USERNAME');
    var toggle = localStorage.getItem('TOGGLEME');
    this.setState({ ISUAEPASS: localStorage.getItem('isUAEPASS') })
    localStorage.clear();
    if (local !== null) {
      localStorage.setItem('siteLang', local);
    }
    if (username != null && toggle != null) {
      localStorage.setItem('USERNAME', username);
      localStorage.setItem('TOGGLEME', toggle);
    }
    cookie.remove('chatbotInfoName', { path: '/' });
    cookie.remove('chatbotInfoMobile', { path: '/' });
    cookie.remove('chatbotInfoEmail', { path: '/' });
    cookie.remove('chatbotInfoPhoto', { path: '/' });

    this.setState({ isLogout: true })
  }

  isLogedIn() {
    return localStorage.getItem('accessToken')
  }

  redirectToHome() {
    HISTORY.push('/eservices')
  }

  componentDidUpdate() {
    if (this.state.isLogout) {
      if (this.state.ISUAEPASS) {
        window.location.href = process.env.UAEPASS_LOGOUT
      } else {
        this.redirectToHome();
      }
    }
  }

  isEservicesRoute() {
    return window.location.pathname == '/eservices' ||
      window.location.pathname == '/contractorLogin' ||
      window.location.pathname == '/chatbotWeb' ||
      window.location.pathname == '/customerLogin';
  }

  acctoggle(e) {
    e.preventDefault();
    this.setState({
      accessibilityClick: !this.state.accessibilityClick
    });
  }

  accBtnClick() {
    this.setState({
      accPopBg: !this.state.accPopBg,
      accBtnClick: !this.state.accBtnClick
    })
  }

  searchClick() {
    this.setState({
      searchClick: !this.state.searchClick
    })
  }

  mobSearchClick() {
    this.setState({
      mobSearchClick: !this.state.mobSearchClick
    })
  }

  menuBtnClick() {
    this.setState({
      menuBtnClick: !this.state.menuBtnClick
    })
  }

  myTheme(e) {
    var id = e.target.id
    if (id === "theme-green") {
      this.props.appRef.changeTheme('theme-green')
    } else if (id === "theme-red") {
      this.props.appRef.changeTheme('theme-red')
    } else if (id === "theme-black") {
      this.props.appRef.changeTheme('theme-black')
    } else {
      this.props.appRef.changeTheme('theme-normal')
    }
  }

  showLeavePopup(address) {
    this.setState({ showLeavePopup: true, urlAddress: address })
  }

  hideLeavePopup() {
    this.setState({ showLeavePopup: false, urlAddress: "" })
  }

  enter(e) {
    if (e.key === 'Enter') {
      window.location.href = '/searchResult?query=' + this.state.query
    }
  }

  getTooltips() {
    return (
      <div>
        <ReactTooltip id='Contact' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='ContactH' place='bottom' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='accessability' place='bottom' className="toolTip">
          <span>{translate('accessibility')}</span>
        </ReactTooltip>
        <ReactTooltip id='settings' place='bottom' className="toolTip">
          <span>{translate('settings-lbl')}</span>
        </ReactTooltip>
        <ReactTooltip id='notification' place='bottom' className="toolTip">
          <span>{translate('Notifications')}</span>
        </ReactTooltip>
        <ReactTooltip id='logout' place='bottom' className="toolTip">
          <span>{translate('Logout')}</span>
        </ReactTooltip>
        <ReactTooltip id='profile' place='bottom' className="toolTip">
          <span>{translate('profile')}</span>
        </ReactTooltip>
        <ReactTooltip id='whatsapp' className="toolTip">
          <span>{translate('whatsapp')}</span>
        </ReactTooltip>
        <ReactTooltip id='facebook' className="toolTip">
          <span>{translate('facebook')}</span>
        </ReactTooltip>
        <ReactTooltip id='twitter' className="toolTip">
          <span>{translate('twitter')}</span>
        </ReactTooltip>
        <ReactTooltip id='instagram' className="toolTip">
          <span>{translate('instagram')}</span>
        </ReactTooltip>
        <ReactTooltip id='youtube' className="toolTip">
          <span>{translate('youtube')}</span>
        </ReactTooltip>
        <ReactTooltip id='home' place='bottom' className="toolTip">
          <span>{translate('Home')}</span>
        </ReactTooltip>
        <ReactTooltip id='userManual' place='bottom' className="toolTip">
          <span>{translate('EservicesUserManual')}</span>
        </ReactTooltip>
        <ReactTooltip id='search' place='bottom' className="toolTip">
          <span>{translate('search')}</span>
        </ReactTooltip>
        <ReactTooltip id='language' place='bottom' className="toolTip">
          <span>{translate('changeLanguage')}</span>
        </ReactTooltip>
        <ReactTooltip id='tour' place='bottom' className="toolTip">
          <span>{translate('siteTour')}</span>
        </ReactTooltip>
        <ReactTooltip id='appStore' className="toolTip">
          <span>{translate('appStore')}</span>
        </ReactTooltip>
        <ReactTooltip id='playStore' className="toolTip">
          <span>{translate('playStore')}</span>
        </ReactTooltip>
        <ReactTooltip id='InternetExplorer' className="toolTip">
          <span>{translate('InternetExplorer')}</span>
        </ReactTooltip>
        <ReactTooltip id='GoogleChrome' className="toolTip">
          <span>{translate('GoogleChrome')}</span>
        </ReactTooltip>
        <ReactTooltip id='Safari' className="toolTip">
          <span>{translate('Safari')}</span>
        </ReactTooltip>
        <ReactTooltip id='Firefox' className="toolTip">
          <span>{translate('Firefox')}</span>
        </ReactTooltip>
        <ReactTooltip id='Chat' className="toolTip">
          <span>{translate('Chat')}</span>
        </ReactTooltip>

        <ReactTooltip id='mbrheTheme' className="toolTip">
          <span>{translate('MBRHETheme')}</span>
        </ReactTooltip>
        <ReactTooltip id='greenWeekness' className="toolTip">
          <span>{translate('greenWeekness')}</span>
        </ReactTooltip>
        <ReactTooltip id='redWeakness' className="toolTip">
          <span>{translate('redWeakness')}</span>
        </ReactTooltip>
        <ReactTooltip id='colourBlind' className="toolTip">
          <span>{translate('ColourBlind')}</span>
        </ReactTooltip>
        <ReactTooltip id='print' className="toolTip">
          <span>{translate('PrintPage')}</span>
        </ReactTooltip>

        <ReactTooltip id='accessibilityLink' className="toolTip">
          <span>{translate('accessibility')}</span>
        </ReactTooltip>
        <ReactTooltip id='careerLink' className="toolTip">
          <span>{translate('ejobs')}</span>
        </ReactTooltip>
        <ReactTooltip id='contactusLink' className="toolTip">
          <span>{translate('contactus')}</span>
        </ReactTooltip>
        <ReactTooltip id='customerCharterLink' className="toolTip">
          <span>{translate('CustomerCharter')}</span>
        </ReactTooltip>
        <ReactTooltip id='faqLink' className="toolTip">
          <span>{translate('faq')}</span>
        </ReactTooltip>
        <ReactTooltip id='informationPolicyLink' className="toolTip">
          <span>{translate('InformationPolicy')}</span>
        </ReactTooltip>
        <ReactTooltip id='privacypolicyLink' className="toolTip">
          <span>{translate('privacypolicy')}</span>
        </ReactTooltip>
        <ReactTooltip id='sitemapLink' className="toolTip">
          <span>{translate('sitemap')}</span>
        </ReactTooltip>
        <ReactTooltip id='termsofuseLink' className="toolTip">
          <span>{translate('termsofuse')}</span>
        </ReactTooltip>
        <ReactTooltip id='confidentiality' className="toolTip">
          <span>{translate('confidentiality')}</span>
        </ReactTooltip>
        <ReactTooltip id='services' className="toolTip">
          <span>{translate('services')}</span>
        </ReactTooltip>
        <ReactTooltip place='bottom' id='opendata' className="toolTip">
          <span>{translate('openData')}</span>
        </ReactTooltip>

        <ReactTooltip id='polls' className="toolTip">
          <span>{translate('polls')}</span>
        </ReactTooltip>
        <ReactTooltip id='iskanapp' className="toolTip">
          <span>{translate('iskanapp')}</span>
        </ReactTooltip>
        <ReactTooltip id='AIchat' className="toolTip">
          <span>{translate('AIchat')}</span>
        </ReactTooltip>
      </div>
    )
  }

  render() {

    let notificationaddClass = ["notificationSideWrapper"]
    if (this.state.notificationBtnClick) {
      notificationaddClass.push('notificationSideWrapperOpen');
    }

    let addClasNav = ["navWrapper"];
    if (this.state.notificationBtnClick) {
      addClasNav.push("navIndex");
    }

    let accClass = ["accessWrapperOpen"];
    if (this.state.accessibilityClick) {
      accClass.push('AccAllOpen');
    }

    let addClasAacc = ["accessBtn"];
    if (this.state.shareClick) {
      addClasAacc.push("accBtnZindex");
    }

    let addClasAaccPopup = ['accPopup'];
    if (this.state.accBtnClick) {
      addClasAaccPopup.push('accPopupOpen');
    }

    let searchBar = ['searchWrapper'];
    if (this.state.searchClick) {
      searchBar.push('searchWrapperOpen');
    }

    let mobSearchBar = ['mobSearchWrapper'];
    if (this.state.mobSearchClick) {
      mobSearchBar.push('mobSearchWrapperOpen');
    }

    let mobMenuaddClass = ["mobMenuWrapper"];
    if (this.state.menuBtnClick) {
      mobMenuaddClass.push('mobMenuWrapperOpen');
    }

    let {
      stepsEnabled,
      // steps,
      // stepsAr,
      initialStep
    } = this.state;

    return (
      <div className="headerWrapper" id='header_wrapper'>
        <Header />
        <div className={addClasNav.join(' ')} id='header'>
          {/* <Steps
          enabled={stepsEnabled}
          // steps={this.context.state.siteLang === LOCALES.ENGLISH ? steps : stepsAr}
          initialStep={initialStep}
          onExit={this.onExit}
          options={{
            hideNext: false,
            hidePrev: true,
            showBullets: false
          }}
        /> */}
          {this.state.showLeavePopup ? <LeavingWeb url={this.state.urlAddress} hide={this.hideLeavePopup} /> : null}
          {this.isLogedIn() ? <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} /> : null}

          {/* notification */}
          {this.state.notificationBg ? <div className="notificationSideBg" onClick={this.handleAddClick}></div> : null}
          {this.isLogedIn() ?
            <div className={notificationaddClass.join(' ')}>
              <div className="notificationhead">
                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bell2.svg'} alt='notification' />
                <span>{translate('Notifications')}</span>
              </div>
              {local ? <NotificationsComponent close={this.handleAddClick} url={CONSTANTS.notificationsEndpoint + local} /> : null}
            </div> : null}

          {/* Mobile Menu */}
          <div className={mobMenuaddClass.join(' ')}>
            <div className='mobMenuHeaderWrapper'>
              <div className='container'>
                <div className='row'>
                  <div className='mobMenuHeader'>
                    <button className="menuCloseBtn" onClick={this.menuBtnClick.bind(this)}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close_black.svg'} alt='close' /></button>
                    <div className="mobLogo">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.svg'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.svg'} alt='MBRHE' /></a>}</div>
                    {!this.isEservicesRoute() && this.isLogedIn() ? <button className="mobSerchBtn" place='bottom' data-tip data-for='search' onClick={this.mobSearchClick.bind(this)}><i class="navIcons icon-search"></i></button> : null}
                    <button className="mobSerchBtn" place='bottom' data-tip data-for='search' onClick={this.mobSearchClick.bind(this)}><i class="navIcons icon-search"></i></button>
                  </div>
                </div>
              </div>
            </div>

            <div className='mobMenuBodyWrapper'>
              <div className='mobMenuBody'>
                <div className='container'>
                  <div className='row'>
                    <strong>{translate('mainmenu')}</strong>
                    <ul className="mobTextMenu">
                      {!this.isEservicesRoute() && this.isLogedIn() ?
                        <li className="homeBtn">
                          {this.state.userType === "1" ?
                            // <a data-tip data-for='home' href='/customerDashboard'><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/home1.svg'} alt='Home' /></i></a>
                            // : <a data-tip data-for='home' href='/contractorDashboard'><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/home1.svg'} alt='Home' /></i></a>}
                            <a data-tip data-for='home' href='/customerDashboard'><span>{translate('Home')}</span></a>
                            : <a data-tip data-for='home' href='/contractorDashboard'><span>{translate('Home')}</span></a>}
                        </li> : <li className="homeBtn">
                          <a data-tip data-for='home' href='https://www.mbrhe.gov.ae/en/'><span>{translate('Home')}</span></a>
                        </li>}
                      {/* <li className='userManual'>
                        <a value="userManual" data-tip data-for='opendata' onClick={() => this.showLeavePopup("https://www.dubaipulse.gov.ae/category/housing")}><span>{translate('opendata')}</span></a>
                      </li> */}
                      {this.openDataHeader(this.context.state.siteLang)}
                      {this.contactUsHeader(this.context.state.siteLang)}
                      {this.videoCallHeader(this.context.state.siteLang)}
                      {this.contactLeadersHeader(this.context.state.siteLang, "menu-toggle", 'sub-menu')}
                      {this.initiativesHeader(this.context.state.siteLang, "menu-toggle", 'sub-menu')}
                      <li><a href='https://www.mbrhe.gov.ae/MediaCenter/News'>{translate('news')}</a></li>
                    </ul>
                  </div>
                </div>

                <div className={mobSearchBar.join(' ')}>
                  <button className="searchCloseBtn" onClick={this.mobSearchClick.bind(this)}><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close_black.svg'} alt='close' /></button>
                  <div className="searchgroup searchInputWrapper">
                    <label htmlFor="searchInput" className="searchBoxText">Search</label>
                    <input type="text" id="searchInput" className="form-control" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? "Search" : "بحث"} onChange={(e) => { this.setState({ query: e.target.value }) }} onKeyDown={this.enter} />
                    <div className="searchgroupBtn">
                      <button type="button" onClick={event => window.location.href = '/searchResult?query=' + this.state.query}>
                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/search_blue.svg'} alt="" />
                      </button>
                      <button className="searchCloseBtn" onClick={this.mobSearchClick.bind(this)}>X</button>
                    </div>
                  </div>
                </div>

              </div>
              <div className='mobMenuBodyFooter'>
                <div>
                  <ul className="mobFooterMenu">
                    <li onClick={this.accBtnClick.bind(this)} className='accessability'><button value="accessability" place='bottom' data-tip data-for='accessability'><i class="navIcons icon-accessibility"></i></button></li>
                    <LangSwitcher />
                    {!this.isEservicesRoute() && this.isLogedIn() ? <li className='logout'><button data-tip data-for='logout' className="logoutBtn" onClick={this.logout}><i class="navIcons icon-logout"></i></button></li> : null}
                    {/* <li className="navConfidentiality">{this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/en/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a> : <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/ar/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a>}</li> */}
                  </ul>
                </div>
                <div className='mobMenuGovLogo'>
                  {this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.smartdubai.ae/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a> : <a href="https://www.smartdubai.ae/ar/home-page" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/gov-dubai.svg'} alt='Government of dubai' /></a>}
                </div>
              </div>
            </div>
          </div>
          {/* End mobile menu */}

          <div className="container-fluid">
            {/* Acc Popup */}
            {this.state.accPopBg ? <div className="PopupBk accessibilityPopupBg" onClick={this.accBtnClick.bind(this)}></div> : null}
            {/* {this.state.accBg ? <div className="PopupBk" onClick={this.accBtnClick.bind(this)}></div> : null} */}
            {/* <div className="PopupBk" id='accPopup'></div> */}
            <div className={addClasAaccPopup.join(' ')}>
              <button className="popupClose" onClick={this.accBtnClick.bind(this)}>
                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/close_black.svg'} alt='Close' />
              </button>
              <div className="accPopupContent">
                <section>
                  <strong>{translate('peopleofdetermination')}</strong>
                  <ul>
                    <li><a href="https://www.mbrhe.gov.ae/People-of-Determination-Services">{translate('peopleOfDeterminationServices')}</a></li>
                    <li><a href="https://www.mbrhe.gov.ae/Facilities-and-privileges">{translate('facilitiesandprivileges')}</a></li>
                    <li><a href="https://www.mbrhe.gov.ae/Services/Charter/People-of-Determination-Charter">{translate('peopleOfDeterminationCharter')}</a></li>
                    <li><a href="https://www.mbrhe.gov.ae/People-of-determination-Initiatives">{translate('initiatives')} </a></li>
                    <li><a href="https://www.mbrhe.gov.ae/Contact--Us">{translate('contactus')}</a></li>
                  </ul>
                </section>

                {/* <section>
                  <strong>{translate('screenreader')}</strong>
                  <p>{translate('listenPage')}</p>
                  <button data-tip data-for='print' className="accPrintBtn" onClick={() => window.print()}><i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/print.svg'} alt='notification' /></i>{translate('Print')}</button>
                </section> */}

                <section>
                  <strong>{translate('textResize')}</strong>
                  <p>{translate('ifYouHaveTroubleReadingText')}</p>
                  <div className="fontSizeChangeWrapper">
                    <SwitchTextSize
                      default={14} // numerical default font size value
                      step={1} // numerical quantity that increases / decreases
                      min={11} // numerical min font size value
                      max={17} // numerical max font size value
                      suffix={"px"} // string suffix property font-size css
                      store={localStorage} // object store to save current font size value
                      storeKey="SwitchTextSize" // string of store key
                    />
                  </div>
                </section>

                <section>
                  <strong>{translate('themeSwitch')}</strong>
                  <p>{translate('useSwitchChangeTheme')}</p>

                  <label htmlFor="theme-normal" data-tip data-for='mbrheTheme'>
                    <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") === null || localStorage.getItem("appTheme") === "theme-normal"} id="theme-normal" type="radio" value="normal" />
                    {translate('MBRHETheme')}</label>

                  <label htmlFor="theme-black" data-tip data-for='colourBlind'>
                    <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-black"} id="theme-black" type="radio" value="Black" />
                    {translate('ColourBlind')}</label>

                  <label htmlFor="theme-green" data-tip data-for='redWeakness'>
                    <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-green"} id="theme-green" type="radio" value="Green" />
                    {translate('redWeakness')}</label>

                  <label htmlFor="theme-red" data-tip data-for='greenWeekness'>
                    <input onChange={this.myTheme} checked={localStorage.getItem("appTheme") && localStorage.getItem("appTheme") === "theme-red"} id="theme-red" type="radio" value="red" />
                    {translate('greenWeekness')}</label>

                </section>
              </div>
            </div>

            {/* <div className="social-side-menu">
            <span data-tip data-for='whatsapp' onClick={() => this.showLeavePopup("https://api.whatsapp.com/send/?phone=+97142029444&text=Hello%20Muftah,%20Could%20I%20know%20the%20Housing%20Application%20Status")}>
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/whatsapp_left_menu.svg"} alt='Click to Whatsapp' />
            </span>
            <span data-tip data-for='facebook' onClick={() => this.showLeavePopup("https://www.facebook.com/mbrhedubai")}>
              <i className="fa fa-facebook"> </i>
            </span>
            <span data-tip data-for='twitter' onClick={() => this.showLeavePopup("https://twitter.com/mbrhe_gov")}>
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/twitter_left_menu.svg"} alt='Click to X'/>
            </span>
            <span data-tip data-for='instagram' onClick={() => this.showLeavePopup("https://www.instagram.com/mbrhe_gov/")}>
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/instagram_left_menu.svg"} alt='Click to Instagram' />
            </span>
            <span data-tip data-for='youtube' onClick={() => this.showLeavePopup("https://www.youtube.com/user/mrheDubai")}>
              <img href="#!" src={"https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/youtube_left_menu.svg"} alt='Click to YouTube'/>
            </span>
          </div> */}

            <div className="row nav_wrapper">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 navContainer">
                    <ul className="navLeft">
                      <li className='mobMenuBtn'><button onClick={this.menuBtnClick.bind(this)}><i class="navIcons icon-menu"></i></button></li>
                      {!this.isEservicesRoute() && this.isLogedIn() ?
                        <li className="homeBtn mobH">
                          {this.state.userType === "1" ? <a data-tip data-for='home' href='/customerDashboard'>{translate('Home')}</a> : <a data-tip data-for='home' href='/contractorDashboard'>{translate('Home')}</a>}
                        </li> :
                        <li className="homeBtn mobH">
                          <a data-tip data-for='home' href='https://www.mbrhe.gov.ae/en/'>{translate('Home')}</a>
                        </li>}
                      {this.openDataHeader()}
                      {this.contactUsHeader(this.context.state.siteLang)}
                      {this.videoCallHeader(this.context.state.siteLang)}
                      {this.contactLeadersHeader(this.context.state.siteLang)}
                      {this.initiativesHeader(this.context.state.siteLang, "initiatives mobH", "")}
                      {this.newsHeader(this.context.state.siteLang)}
                    </ul>
                    {this.isLogedIn() ?
                      <div className="mrheMobLogo">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.svg'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe.svg'} alt='MBRHE' /></a>}</div> :
                      <div className="mrheMobLogo">{this.context.state.siteLang === LOCALES.ENGLISH ? <a href="https://www.mbrhe.gov.ae/en/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe_white.svg'} alt='MBRHE' /></a> : <a href="https://www.mbrhe.gov.ae/ar/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/mbrhe_white.svg'} alt='MBRHE' /></a>}</div>
                    }
                    <ul className="navRight">
                      {!this.isEservicesRoute() && this.isLogedIn() ? <li className='notificationTop mobH' onClick={this.handleAddClick.bind(this)}><button data-tip data-for='notification'><span class="navIcons icon-bell"></span></button><i>{this.state.payload && this.state.payload.notifications}</i></li> : null}
                      {!this.isEservicesRoute() && this.isLogedIn() ? <li className='settingsBtn mobH'><button data-tip data-for='settings'><i class="navIcons icon-cog"></i></button><Settings onRef={ref => (this.settings = ref)} /></li> : null}
                      {this.isLogedIn() ? <li className='tour mobH'><button onClick={this.toggleSteps} data-tip data-for='tour'><i class="navIcons icon-globe"></i></button></li> : null}
                      {!this.isEservicesRoute() && this.isLogedIn() ? <li className='navSerch'><button className="navSerchBtn" place='bottom' data-tip data-for='search' onClick={this.searchClick.bind(this)}><i class="navIcons icon-search"></i> <span>{translate('search')}</span></button></li> : <li className='navSerch'><a href={'https://www.mbrhe.gov.ae/en/search?query&categoriesen=1,3,5'} className="navSerchBtn" place='bottom' data-tip data-for='search'><i class="navIcons icon-search"></i> <span>{translate('search')}</span></a></li>}
                      {/* login page Search BTN*/}
                      {/* end login page Search BTN*/}
                      <li onClick={this.accBtnClick.bind(this)} className='accessability mobH'><button value="accessability" place='bottom' data-tip data-for='accessability'><i class="navIcons icon-accessibility"></i></button></li>
                      <LangSwitcher />
                      <li className='profile mobH'>
                        {!this.isEservicesRoute() && this.isLogedIn() ?
                          <Link data-tip data-for='profile' to={{ pathname: this.state.userType === "1" ? '/userProfile' : '/contractorProfile', getPayload: this.getPayload }} className="user">
                            {this.state.payload && this.state.payload.name}
                            <div className="user_image"> {this.state.payload && this.state.payload.photo ? <img src={`data:image/png;base64,${this.state.payload.photo}`} height="31" width="31" alt='User' /> : <i class="navIcons icon-user-o"></i>}</div>
                          </Link> : null}
                      </li>
                      {!this.isEservicesRoute() && this.isLogedIn() ? <li className='logout mobH'><button data-tip data-for='logout' className="logoutBtn" onClick={this.logout}><i class="navIcons icon-logout"></i></button></li> : null}
                      {/* <li className="navConfidentiality">{this.context.state.siteLang === LOCALES.ENGLISH ? <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/en/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a> : <a data-tip data-for='confidentiality' href="https://www.mbrhe.gov.ae/ar/Services/Charter/Confidentiality-of-Customers-Information-Charter/" ><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Confidentiality_Charter_icon.svg'} alt='Confidentiality Charter' /></a>}</li> */}
                    </ul>
                  </div>
                </div>
              </div>

              <div className={searchBar.join(' ')} id='search_wrapper'>
                <div className="container-fluid">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="searchgroup searchInputWrapper">
                          <label htmlFor="searchInput" className="searchBoxText">{translate('search')}</label>
                          <input type="text" id="searchInput" className="form-control" placeholder={this.context.state.siteLang === LOCALES.ENGLISH ? "Search" : "بحث"} onChange={(e) => { this.setState({ query: e.target.value }) }} onKeyDown={this.enter} />
                          <div className="searchgroupBtn">
                            <button type="button" onClick={event => window.location.href = '/searchResult?query=' + this.state.query}>
                              <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/search_blue.svg'} alt="" />
                            </button>
                            <button className="searchCloseBtn" onClick={this.searchClick.bind(this)}>X</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='searchLinksWrap'>
                    <div className='container-fluid'>
                    <div className='container'>
                      <div>
                        <h3>{translate('faq')}</h3>
                        <ul>
                        <li>
                            <a href='#'>{translate('general')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('engineeringDep')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('clientHappinessDep')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('supportServicesDepartment')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('landGrant')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('grantExchange')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('constructionLoan')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('replacementLoan')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('maintenanceAdditionLoan')}</a>
                          </li>
                        </ul>
                      </div>
                      <div>
                      <h3>{translate('services')}</h3>
                        <ul>
                          <li>
                          <a href='#'>{translate('landGrant')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('maintenanceAdditionGrant')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('constructionLoan')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('maintenanceAdditionLoan')}</a>
                          </li>
                          <li>
                            <a href='#'>{translate('constructionGrant')}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {this.getTooltips()}
        </div>
      </div>
    );
  }

  contactLeadersHeader = (language, claz, clazSub) => {
    return (<li className='connectLeaders mobH'>
      <a href='#!' className={claz}>{translate('connectTheleaders')}</a>
      <ul className={clazSub}>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Connect-with-the-Leaders#housing' : 'https://www.mbrhe.gov.ae/ar/Connect-with-the-Leaders#housing'}>{translate('housingSector')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Connect-with-the-Leaders#ClientHappiness' : 'https://www.mbrhe.gov.ae/ar/Connect-with-the-Leaders#ClientHappiness'}>{translate('clientHappinessDep')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Connect-with-the-Leaders#Investment' : 'https://www.mbrhe.gov.ae/ar/Connect-with-the-Leaders#Investment'}>{translate('investmentDep')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Connect-with-the-Leaders#Engineering' : 'https://www.mbrhe.gov.ae/ar/Connect-with-the-Leaders#Engineering'}>{translate('engineeringDep')}</a></li>
      </ul>
    </li>)
  }

  initiativesHeader = (language, claz, clazSub) => {
    return (<li>
      <a href='#!' className={claz}>{translate('initiatives')}</a>
      <ul className={clazSub}>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/howtobuildahouse' : 'https://www.mbrhe.gov.ae/ar/howtobuildahouse'}>{translate('howTobuildHouse')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/WorldHabitatDay' : 'https://www.mbrhe.gov.ae/ar/WorldHabitatDay'}>{translate('worldHabitatDay')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Decobuild' : 'https://www.mbrhe.gov.ae/ar/Decobuild'}>{translate('Decobuild')}</a></li>
        <li><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Offers-and-Promotions' : 'https://www.mbrhe.gov.ae/ar/Offers-and-Promotions'}>{translate('offersAndpromotions')}</a></li>
      </ul>
    </li>)
  }

  newsHeader = (language) => {
    return (<li className='news mobH'><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/MediaCenter/News' : 'https://www.mbrhe.gov.ae/ar/MediaCenter/News'}>{translate('news')}</a></li>)
  }

  videoCallHeader = (language) => {
    return (<li className='bookAVideoCall mobH'><a href={this.isEnglish(language) ? 'https://www.mbrhe.gov.ae/en/Book-Appointment' : 'https://www.mbrhe.gov.ae/ar/Book-Appointment'}>{translate('bookAvideocall')}</a></li>)
  }

  contactUsHeader = (language) => {
    return (<></>)
    return (<li className='contactUs mobH'>{this.isEnglish(language) ? <a data-tip data-for='ContactH' href="https://www.mbrhe.gov.ae/en/AboutUs/Contact-Us" ><span>{translate('contactUS')}</span></a> : <a data-tip data-for='ContactH' href="https://www.mbrhe.gov.ae/ar/AboutUs/Contact-Us" ><span>{translate('contactUS')}</span> </a>}</li>)
  }

  openDataHeader = () => {
    return (<></>)
    return (<li className='userManual mobH'><a data-tip data-for='opendata' onClick={() => this.showLeavePopup("https://www.dubaipulse.gov.ae/category/housing")}><span>{translate('opendata')}</span></a></li>)
  }

  isEnglish = (language) => {
    return language === LOCALES.ENGLISH
  }
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
}
export default Nav;
import './login.css'
import React from "react"
import history from "../../History"
import LoginStore from "./LoginStore"
import queryString from 'query-string'
import AuthService from './AuthService'
import BaseRedirect from "./BaseRedirect"
import { Redirect } from 'react-router-dom'
import DOMPurify from '../../utils/DOMPurify'
import LoadingBar from 'react-top-loading-bar'
import CommonUtils from '../../utils/CommonUtils'
import { AppContext } from '../../Providers/context'
import translate from '../../Providers/i18n/Translate'
import FloatingLabelInput from 'react-floating-label-input'
// import { Leonardo } from "@leonardo-ai/sdk";
// import { CreateGenerationResponse } from "@leonardo-ai/sdk/dist/sdk/models/operations";
// import { ControlnetType, SdGenerationSchedulers, SdGenerationStyle, SdVersions } from "@leonardo-ai/sdk/dist/sdk/models/shared";

class CustomerLogin extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            username: '',
            pwd: '',
            apiError: '',
            emiratesid: '',
            otpMessage: '',
            loginbyotp: '',
            kioskError: '',
            loading: false,
            submitted: false,
            isUAEPASS: false,
            isChecked: false,
            uaepass_token: '',
            kioskClick: false,
            isLoginHelp: false,
            submittedOTP: false,
            isShowOTPField: false,
            uaepassPopClick: false,
            isUaepassClicked: false,
            isShowEmiratesField: true,
            iscustomerOtpWrapper: false,
            iscustomerSigninWrapper: false
        };

        this.backPress = this.backPress.bind(this)
        // this.getImg = this.getImg.bind(this)
        this.getServiceId = this.getServiceId.bind(this)
        this.clickUAEPass = this.clickUAEPass.bind(this)
        this.loginHelpClick = this.loginHelpClick.bind(this)
        this.otpPasswordBkClick = this.otpPasswordBkClick.bind(this)
        this.customerOtpWrapperClick = this.customerOtpWrapperClick.bind(this)
        this.loginUsingEmiratesIdClick = this.loginUsingEmiratesIdClick.bind(this)
        this.loginUsingPasswordOrOTPClick = this.loginUsingPasswordOrOTPClick.bind(this)
    }

    otpPasswordBkClick() {
        this.setState({
            iscustomerOtpWrapper: !this.state.iscustomerOtpWrapper,
            iscustomerSigninWrapper: !this.state.iscustomerSigninWrapper,
            otpMessage: '',
            apiError: ''
        });
    }

    customerOtpWrapperClick() {
        this.setState({
            iscustomerOtpWrapper: !this.state.iscustomerOtpWrapper,
            iscustomerSigninWrapper: !this.state.iscustomerSigninWrapper,
            isShowEmiratesField: true,
            isShowOTPField: false,
        });
    }

    loginHelpClick() {
        this.setState({
            isLoginHelp: !this.state.isLoginHelp
        });
    }

    backPress() {
        history.push('/eservices')
    }

    onChange = (event) => {
        if (this.state.apiError) {
            this.setState({ apiError: '' });
        }
        this.setState({ [event.target.name]: event.target.value });
    }

    onBlur = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    onFocus = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    componentWillUnmount() {
        LoginStore.removeChangeListener(this._onChange);
    }

    _onChange() {
        this.LoadingBar && this.LoadingBar.complete()
        this.setState(this._getResponse());
    }

    _getResponse() {
        if (LoginStore.isLoggedIn() || LoginStore.isSuccess()) {
            return {
                otpMessage: LoginStore.response,
                userLoggedIn: LoginStore.isLoggedIn()
            };
        } else {
            return {
                apiError: LoginStore.response,
            };
        }
    }

    loginUsingPasswordOrOTPClick(e) {
        e.preventDefault();
        this.setState({ submittedOTP: true })
        const { username, pwd, loginbyotp } = this.state;
        // stop here if form is invalid
        if (!(username && pwd || loginbyotp)) {
            return;
        }
        if (!this.state.isShowOTPField && this.state.loginbyotp) {
            this.setState({ isShowEmiratesField: false })
            this.setState({ isShowOTPField: true })
        }
        this.setState({ loading: true })
        this.setState({ otpMessage: '' })
        this.setState({ apiError: '' })
        this.LoadingBar && this.LoadingBar.continuousStart()
        AuthService.customerLoginUsingOtpNo(this.state.emiratesid, this.state.loginbyotp, this.context.state.siteLang)
            .catch(function (error) {
                this.catchBody(error);
            }.bind(this));
        this.setState({ loading: false });
    }

    loginUsingEmiratesIdClick(e) {
        e.preventDefault();
        this.setState({ submitted: true })
        this.setState({ submittedOTP: false })
        const { emiratesid } = this.state;
        // stop here if form is invalid
        if (!(emiratesid)) {
            return;
        }
        this.setState({ loading: true });
        this.setState({ apiError: '' })
        AuthService.customerLoginUsingEmiratedId(this.state.emiratesid, this.context.state.siteLang)
            .catch(function (error) {
            }.bind(this));
        this.setState({ loading: false });
    }

    catchBody(error) {
        var parseError = JSON.parse(error.response);
        var errorDescription = parseError.error_description;
        var error = parseError.error;
        this.setState({ kioskError: error });
        // window.location.href = process.env.UAEPASS_LOGOUT
        this.setState({ apiError: errorDescription });
        if (this.state.kioskError !== '' && this.state.kioskClick) {
            this.setState({ iscustomerSigninWrapper: true });
        } else {
            this.setState({ iscustomerSigninWrapper: false });
        }
        this.setState({ otpMessage: "" });
    }

    kioskClick(e) {
        e.preventDefault();
        window.location.href = process.env.UAEPASS_LOGOUT
        this.setState({
            kioskClick: !this.state.kioskClick
        });
    }

    uaepassPopClick(e) {
        e.preventDefault();
        this.setState({
            uaepassPopClick: !this.state.uaepassPopClick
        });
    }

    componentDidMount() {
        this.setState({
            iscustomerSigninWrapper: true
        });
        // this.state.isUaepassClicked && 
        this.loginWithUAEPass();
        this.changeListener = this._onChange.bind(this);
        LoginStore.addChangeListener(this.changeListener);
        // this.getImg();
        if (!this.isLogedIn()) {
            require('./style_24.css')
        }
    }

    isLogedIn() {
        return localStorage.getItem('accessToken')
    }

    loginWithUAEPass() {
        this.setState({ isUAEPASS: true });
        let url = this.props.location.search;
        // if (url !== "" && !queryString.parse(url).serviceID) {
        if (url !== "") {
            let value = queryString.parse(url);
            if (value.token && value.token !== '') {
                //Show loading here
                this.setState({ isUaepassClicked: true })
                this.setState({ uaepass_token: value.token })
                localStorage.setItem('isUAEPASS', true)
                AuthService.customerLoginUsingPassword(this.state.username, this.state.pwd, value.token, this.context.state.siteLang)
                    .catch(function (error) {
                        this.setState({ isUaepassClicked: false, kioskClick: true })
                        this.catchBody(error);
                    }.bind(this));
            } else {
                if (value.error && value.error !== '') {
                    this.setState({ uaepassPopClick: true })
                    this.setState({ iscustomerSigninWrapper: true });
                } else {
                    window.location.href = process.env.UAEPASS_LOGOUT
                }
            }
        }
    }

    clickUAEPass() {
        this.setState({ isUaepassClicked: true })
    }

    isLoggedIn() {
        return localStorage.getItem('accessToken')
    }

    getUserType() {
        return localStorage.getItem('userType')
    }

    getUaepassUrl(uaepassUrl) {
        var urlS, urlE, url = ''
        urlS = uaepassUrl.substring(0, uaepassUrl.indexOf("state") + 6)
        urlE = uaepassUrl.substring(uaepassUrl.indexOf("state") + 6, uaepassUrl.length)
        url = urlS + this.getServiceId(this.props.location.search) + urlE.replace('Qy6hZ7SR9c566Sk8', '')
        console.log(urlS + this.getServiceId(this.props.location.search) + urlE)
        return url
    }

    getServiceId(params) {
        var serviceID = ''
        try {
            serviceID = queryString.parse(params).serviceID
        } catch (error) {
            console.log(error)
        }
        return serviceID
    }

    render() {
        if (this.isLoggedIn() && this.getUserType() == 1) {
            return CommonUtils.getRedirection(this.props.location.search, false)
        } else if (this.isLoggedIn()) {
            return <Redirect to='/contractorDashboard' />
        } else {
            const { isUaepassClicked, uaepassPopClick, kioskError, kioskClick, apiError, userLoggedIn, emiratesid, otpMessage, loginbyotp,
                submittedOTP, isShowEmiratesField, isShowOTPField } = this.state;
            return (
                <div className="container-fluid appWrapper">
                    <LoadingBar className='loagingbar' height={0} color='#0091ff' onRef={ref => (this.LoadingBar = ref)} />
                    {/* Kiosk */}
                    {kioskClick && kioskError ? <div className="PopupBk"></div> : null}
                    {kioskClick && kioskError ? <div className="kiosksPopup">
                        <div className="img"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/Kiosk.svg'} alt="Kiosk" /></div>
                        <strong> <div dangerouslySetInnerHTML={{ __html: DOMPurify(kioskError) }} /></strong>
                        <p><div dangerouslySetInnerHTML={{ __html: DOMPurify(apiError) }} /></p>
                        <div className="button">
                            <a href="https://selfcare.uaepass.ae/locations" target="_blank">{translate('kioskLocation')}</a>
                            <button value="kiosk" onClick={this.kioskClick.bind(this)}>{translate('iWilldolater')}</button>
                        </div>
                    </div> : null}
                    {/* END-Kiosk */}
                    {/* UAE PASS ERROR */}
                    {isUaepassClicked ? <div className="uaePassLoadingWrapper">
                        <div className="container-fluid">
                            <div className="uaePassLoadingHeader"></div>
                            <div className="uaePassLoading_wrapperOut">
                                <div className="uaePassLoading_wrapper">
                                    <div className="customer_section">
                                        <div className="uaePassLoadingHeaderText"></div>
                                        <div className="uaePassLoadingHeaderImg">
                                            <span className="animate_bg"></span>
                                        </div>
                                        <div className="uaePassLoadingRoundImg">
                                            <span></span>
                                        </div>
                                        <div className="uaePassLoadingArea">
                                            <div className="uaePassLoadingSection">
                                                <span className="animate_bg"></span>
                                            </div>
                                            <div className="uaePassLoadingLoginOr">
                                                <span></span>
                                            </div>
                                            <div className="uaePassLoadingSection">
                                                <span className="animate_bg"></span>
                                            </div>
                                            <div className="uaePassLoadingHelpText">
                                                <span className="animate_bg"></span>
                                                <span className="animate_bg"></span>
                                                <span className="animate_bg"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> : null}
                    {uaepassPopClick ? <div className="PopupBk"></div> : null}
                    {uaepassPopClick ? <div className="uaepassErrorPopup">
                        <p>{translate('cancleAuthentication')}</p>
                        <div className="button">
                            <button onClick={this.uaepassPopClick.bind(this)}>{translate('ok')}</button>
                        </div>
                    </div> : null}
                    {/* UAE PASS ERROR */}
                    <div className="login_wrapper">
                        <div className="customer_section">
                            <div className="loginHeaderText">
                                {translate('customerLogin')}
                                <a href="" className="contractorLogBtnBack">
                                    <span>
                                        <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/loginBack.svg'} alt="Click to Back" />
                                    </span>
                                    <span onClick={this.backPress}>{translate('back')}</span>
                                </a>
                            </div>
                            <div className="login_headerImg">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="headerImg customer_bg">
                                            <div className="icon">
                                                <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/customerIcon.png'} alt="Customer" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row login_formArea">
                                {this.state.iscustomerSigninWrapper ? <div className="col-sm-12 LoginSection customerSigninWrapper">
                                    <div className="logincolSection">
                                        <br></br>
                                        {/* create a click event and use that event instead href and make background blure to disable user interaction and add some loading indicators */}
                                        <div className="formSection signincus loginWhiteBg uaePassBtnWrap">
                                            {/* <a onClick={this.clickUAEPass} href={DOMPurify(this.getUaepassUrl(process.env.UAEPASS_URL) + this.context.state.siteLang)} className="uaePassBtn"><span>{translate('loginWith')}</span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaePass.png'} alt="uaePass" /><span>{translate('uaePass')}</span></a> */}
                                            <a onClick={this.clickUAEPass} href={DOMPurify(this.getUaepassUrl(process.env.UAEPASS_URL) + this.context.state.siteLang)} className="uaePassBtn"><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaepass.svg'} alt="uaePass" /><span>{translate('loginWith')}</span></a>
                                            {/* <span>{translate('uaePass')}</span> */}
                                            {/* <a onClick={this.clickUAEPass} href={""} className="uaePassBtn"><span>{translate('loginWith')}</span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/uaePass.png'} /><span>{translate('uaePass')}</span></a> */}
                                        </div>
                                        <div className="createLoginWrapper">
                                            <p>
                                                <span>{translate("donothaveaccount")} </span>
                                                <a href={DOMPurify(process.env.UAEPASS_CREATE_URL)}><span> {translate("createnew")}</span></a>
                                            </p>
                                            <a href={DOMPurify(process.env.UAEPASS_RECOVERY_URL)}><span>{translate('recoveraccount')}</span></a>
                                        </div>
                                        <BaseRedirect userLoggedIn={DOMPurify(userLoggedIn)} redirect={"/customerDashboard" + this.props.location.search} />
                                    </div>
                                    <div className="loginOr"><span>{translate('or')}</span></div>
                                    <div className="formSection OTP logincolSection">
                                        <a href="javascript:;" className="customerOtpPassword" onClick={this.customerOtpWrapperClick}>{translate('loginWithEmiratesId')}</a>
                                        <div className="helpText">{translate('loginWithEmiratesIdText')}</div>
                                    </div>
                                </div> : null}
                                {this.state.iscustomerOtpWrapper ? <div className="col-sm-12 LoginSection customerOtpWrapper">
                                    <div className="logincolSection">
                                        <div className="formSection">
                                            <div className="formInput EmiratesIDWrapper">
                                                {!userLoggedIn && otpMessage == "" && isShowEmiratesField ?
                                                    <FloatingLabelInput
                                                        id="emiratesid"
                                                        name="emiratesid"
                                                        className="formInputWrapper loginLabel"
                                                        type="text"
                                                        label={translate('emiratesId')}
                                                        onFocus={this.onFocus}
                                                        onChange={this.onChange}
                                                        onBlur={this.onBlur}
                                                        value={this.state.emiratesid}
                                                    /> : null}
                                            </div>
                                            {this.state.submitted && !emiratesid ? <span className="jqueryStepErrorMsgSpan" id="msg_EmiratesID">{translate('requiredField')}</span> : null}
                                            {userLoggedIn || otpMessage !== "" || isShowOTPField ? <div className="formInput CustomerloginOtpNoWr">
                                                <FloatingLabelInput
                                                    id="loginbyotp"
                                                    name="loginbyotp"
                                                    className="formInputWrapper loginLabel"
                                                    type="number"
                                                    label={translate('loginByOtp')}
                                                    onFocus={this.onFocus}
                                                    onChange={this.onChange}
                                                    onBlur={this.onBlur}
                                                    value={this.state.loginbyotp} />
                                                <a href="javascript:;" aria-label="first link" className="reOtp" onClick={this.loginUsingEmiratesIdClick}>
                                                    <img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/otpRefresh.svg'} alt="Resend pin code img" />
                                                </a>
                                            </div> : null}
                                            {submittedOTP && loginbyotp == "" ? <span className="jqueryStepErrorMsgSpan" id="msg_COtpCode">{translate('requiredField')}</span> : null}
                                            {apiError !== "" ? <span className="jqueryStepErrorMsgSpan" id="invalidCustomerLoginMessage"> <div dangerouslySetInnerHTML={{ __html: DOMPurify(apiError) }} /></span> : null}
                                            {userLoggedIn || otpMessage !== "" && emiratesid && !submittedOTP ? <span className="OtpSuccessMessage" id="OtpCustomerSuccessMessage"><div dangerouslySetInnerHTML={{ __html: DOMPurify(otpMessage) }} /></span> : null}
                                        </div>
                                        {otpMessage == "" && !submittedOTP ?
                                            <div className="formSection submitBtn">
                                                <a href="javascript:;" id="OtpCustomer" onClick={this.loginUsingEmiratesIdClick}>{translate('submit')}</a>
                                            </div> : null}
                                        {otpMessage !== "" || submittedOTP ? <div className="formSection submitCustomer ">
                                            <a href="javascript:;" id="btnsubmitCustomerByotp" formAction="Login" onClick={this.loginUsingPasswordOrOTPClick}>{translate('signIn')}</a>
                                        </div> : null}
                                        <BaseRedirect userLoggedIn={DOMPurify(userLoggedIn)} redirect={"/customerDashboard" + this.props.location.search} />

                                        <div className="formSection OTPbk loginBack">
                                            <span className="otpPasswordBk" onClick={this.otpPasswordBkClick}>{translate('back')}</span>
                                            <div className="helpText">{translate('loginWithEmiratesIdText')}</div>
                                        </div>
                                    </div>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }

    // getImg() {
    //     const sdk = new Leonardo({
    //         security: {
    //             bearerAuth: "cea5dbb0-6ad3-4ecd-b8e2-cf32c6916145",
    //         },
    //     });

    //     sdk.generation.createGeneration({
    //         // controlNet: false,
    //         // controlNetType: ControlnetType.Canny,
    //         // guidanceScale: 4,
    //         height: 512,
    //         // initGenerationImageId: "placeat",
    //         // initImageId: "voluptatum",
    //         // initStrength: 5,
    //         modelId: "d45d57c0-8fa6-47f2-b3c2-abf7bffb8dec",
    //         // negativePrompt: "nisi",
    //         numImages: 1,
    //         // numInferenceSteps: 836079,
    //         presetStyle: SdGenerationStyle.Leonardo,
    //         prompt: "2 bedrooms villa floor plan",
    //         // promptMagic: false,
    //         // public: false,
    //         // scheduler: SdGenerationSchedulers.Klms,
    //         sdVersion: SdVersions.V2,
    //         // tiling: false,
    //         width: 512,
    //     }).then((res) => {
    //         if (res.statusCode == 200) {
    //             // handle response
    //             console.log(res)
    //         }
    //     });
    // }
}
export default CustomerLogin;
import React from 'react'
import NoData from '../../utils/nodata'
import { ToastContainer, toast } from 'react-toastify'
import { AppContext } from '../../../Providers/context'
import translate from '../../../Providers/i18n/Translate'
import HousingRequestsStore from './housing-requests-store'
import HousingRequestItem from './housingrequest-widget-item'
import CostumerModuleUtilities from '../../constants/../utils/customerModuleUtilities'
import HousingRequestsService from './housing-requests-service'

class HousingRequestWidget extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            itemsArray: [],
            props: {},
            showConfirmation: false,
            ticketNo: ''
        }

        this.setHousingRequestWidget = this.setHousingRequestWidget.bind(this)
        this.onChange = this.onChange.bind(this)
        this.modifyTicket = this.modifyTicket.bind(this)
        this.deleteSuccess = this.deleteSuccess.bind(this)
        this.deleteTicketClick = this.deleteTicketClick.bind(this)
        this.yes = this.yes.bind(this)
        this.no = this.no.bind(this)
    }

    componentDidMount() {
        this.setState({ props: this.props })
        HousingRequestsStore.addChangeListener(this.onChange)
        HousingRequestsService.fetchHousingRequest(2, "?local=" + this.context.state.siteLang)
    }

    componentWillUnmount() {
        HousingRequestsStore.removeChangeListener(this.onChange)
    }

    onChange() {
        this.setHousingRequestWidget(HousingRequestsStore.response)
        this.setState({ requestsPayload: HousingRequestsStore.response.payload })
        this.deleteSuccess(HousingRequestsStore.deleteHousingRequestResponse)
    }

    modifyTicket(e) {
        const ticketNo = e.currentTarget.name
        const requestType = e.currentTarget.id
        const applicationNo = e.currentTarget.title
        const applicationStatus = e.currentTarget.value
        localStorage.removeItem('ticket')
        if (ticketNo) {
            if (requestType.toString() === '3') {
                this.state.props.history.push({ pathname: '/changeServiceRequest' })
            } else if (requestType.toString() === '1' || requestType.toString() === '2') {
                this.state.props.history.push({ pathname: '/cancelProgressServiceRequest', state: { ticketNo: ticketNo, applicationNo: applicationNo, applicationStatus: applicationStatus } })
            } else {
                localStorage.setItem('requireSocialTechnicalStudy', this.getIsSocialStudyRequired(ticketNo))
                this.state.props.history.push({
                    pathname: '/housingRequestSteps', state: { ticketNo: ticketNo, isModifying: true }
                })
            }
        } else {
            toast.error(e.target.name)
        }
    }

    getIsSocialStudyRequired(requestID) {
        var isSocialStudyRequired = false
        this.state.requestsPayload.some((request) => {
            isSocialStudyRequired = request.requireSocialTechnicalStudy
            return requestID === request.ticket
        })
        return isSocialStudyRequired
    }

    deleteTicketClick(e) {
        e.persist()
        const ticketNo = e.currentTarget.name
        if (ticketNo) {
            this.setState({ ticketNo: ticketNo, showConfirmation: !this.state.showConfirmation })
        }
    }

    yes(e) {
        if (this.state.ticketNo) {
            this.setState({ showConfirmation: !this.state.showConfirmation })
            HousingRequestsService.deleteTicket(this.state.ticketNo)
        }
    }

    no() {
        this.setState({ ticketNo: '', showConfirmation: false })
    }

    deleteSuccess(response) {
        if (response) {
            if (response.success) {
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }
            HousingRequestsStore.clearDeleteResponse()
            HousingRequestsService.fetchHousingRequest(2, "?local=" + this.context.state.siteLang)
            this.no()
        }
    }

    setHousingRequestWidget(response) {
        if (response) {
            var housingRequestItems = []
            var items = response.payload
            if (items) {
                items.map((housingRequest, index) => {
                    let img = "https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/open_new_construction_Loan.svg"
                    let cls = CostumerModuleUtilities.getStatusLableColor(housingRequest.status)
                    return (housingRequestItems.push(<HousingRequestItem deleteClick={this.deleteTicketClick} modifyTicket={this.modifyTicket} key={index} payload={housingRequest} image={img} className={cls} />))
                })
            }
            this.setState({ itemsArray: housingRequestItems })
        }
    }

    render() {
        return (
            <div className="col-md-12 housingRequestsWidgetWrapper">
                <ToastContainer autoClose={1500} hideProgressBar={true} position={toast.POSITION.TOP_CENTER} />
                <div className="widgetWrapper">
                    {/* delete confirmation popup */}
                    {this.state.showConfirmation ?
                        <div className="PopupBk"></div> : null}
                    {this.state.showConfirmation ?
                        <div className="deleteConfirmationPopup">
                            <p>{translate('deleteconfirmation')}</p>
                            <div className="button">
                                <button onClick={this.yes}>{translate('yes')}</button>
                                <button onClick={this.no}>{translate('no')}</button>
                            </div>
                        </div> : null}

                    {/* delete confirmation popup */}
                    <div className="widgetHead">
                        <span><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/HousingRequest.svg'} alt="Housing Request" /></span>
                        <span>{translate('housing-requests-widget-heading')}</span>
                    </div>
                    <div className="widgetContant">
                        <div className="widgetContentIn">
                            {this.state.itemsArray.length > 0 ? this.state.itemsArray : <NoData message={translate('nodata')} />}
                            {this.state.itemsArray.length > 0 ? <div>
                            </div> : null}
                        </div>
                        {this.state.itemsArray.length > 0 ? <div className="widgetsMoreBtn">
                            <hr></hr>
                            <a href={this.state.itemsArray.length > 0 ? "/housingRequests" : null}>{translate('show-more')}<img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/arrow.svg'} alt="" /></a>
                        </div> : null}
                    </div>
                </div>
            </div >
        )
    }
}
export default HousingRequestWidget
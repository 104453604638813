import React from 'react'
import History from '../../History'
import { ScrollToTop } from '../../utils/Scroll'
import { AppContext } from '../../Providers/context'
// import 'owl.carousel/dist/assets/owl.carousel.min.css'
// import 'owl.carousel/dist/assets/owl.theme.default.css'
import translate from '../../Providers/i18n/Translate'
import TypicalVillasUtils from './typical_villas_utils'
import CONSTANTS from '../constants/dashboard-constants'
import { FacebookIcon, EmailIcon, TwitterIcon, WhatsappIcon } from 'react-share'
import AuthenticatedComponent from '../../ApplicationBase/AuthenticatedComponent'
import { FacebookShareButton, EmailShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'

class TypicalVillasDetailComponent extends React.Component {
    static contextType = AppContext
    constructor(props) {
        super(props)
        this.state = {
            index: 0,
            faqPopup: false,
            videoPopup: false,
            pdfPopup: false,
            payload: '',
            requestStatus: localStorage.requestStatus ? localStorage.getItem("requestStatus") : '',
            selectedStyleId: localStorage.styleID ? localStorage.getItem('styleID') : 0,
            imgsArray: [],
            lookupsPayload: localStorage.lookupsPayload ? JSON.parse(localStorage.getItem('lookupsPayload')) : '',
            canApplayTypicalVilla: localStorage.getItem('canApplayTypicalVilla')
        }
        this.goNext = this.goNext.bind(this)
        this.pdfPopClick = this.pdfPopClick.bind(this)
        this.faqPopClick = this.faqPopClick.bind(this)
        this.videoPopClick = this.videoPopClick.bind(this)
        this.setApplications = this.setApplications.bind(this)
        this.getTypicalVillasApplications = this.getTypicalVillasApplications.bind(this)
    }

    faqPopClick() {
        this.setState({ faqPopup: !this.state.faqPopup })
    }

    videoPopClick() {
        this.setState({ videoPopup: !this.state.videoPopup })
    }

    pdfPopClick() {
        this.setState({ pdfPopup: !this.state.pdfPopup })
    }

    componentDidMount() {
        ScrollToTop()
        this.setState({ payload: TypicalVillasUtils.getVilla(Number(localStorage.getItem('villaID'))) })
    }

    goNext() {
        this.setState({ isLoading: true })
        this.setApplications(null)
        // this.getTypicalVillasApplications()
    }

    getTypicalVillasApplications() {
        if (CONSTANTS.authToken) {
            fetch(CONSTANTS.typicalVillasApplicationsEndpoint + "?local=" + this.context.state.siteLang, {
                method: 'GET',
                headers: {
                    'Authorization': CONSTANTS.authToken
                }
            }).then(response => response.json()).then(this.setApplications).catch(() => { this.setState({ isLoading: false }) });
        }
    }

    setApplications(response) {
        History.replace({ pathname: '/typicalVillasSubmit' })
        // this.setState({ isLoading: false })
        // var items = response.payload
        // if (items.length > 0) {
        //     if (items.length > 1) {
        //         localStorage.setItem("applicationsPayload", JSON.stringify(response.payload))
        //         History.replace({ pathname: '/villaApplications' })
        //     } else {
        //         localStorage.setItem("applicationPayload", JSON.stringify(response.payload[0]))
        //         History.replace({ pathname: '/typicalVillasSubmit' })
        //     }
        // }
    }

    render() {
        return (
            <div className="container-fluid newStyleWrapper">
                {/* FAQ POPUP */}
                {this.state.faqPopup ? <div className="PopupBk"></div> : null}
                {this.state.faqPopup ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.faqPopClick}>X</button>
                    <div className="popupShowFile">
                        <iframe src={this.context.state.siteLang === 'en' ? this.state.payload.faq_en : this.state.payload.faq_ar}></iframe>
                    </div>
                </div> : null}
                {/* END FAQ POPUP */}

                {/* VIDEO POPUP */}
                {this.state.videoPopup ? <div className="PopupBk"></div> : null}
                {this.state.videoPopup ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.videoPopClick}>X</button>
                    <div className="popupShowFile">
                        <iframe width="560" height="315" src={"https://youtube.com/embed/" + TypicalVillasUtils.getStyleVideo(this.state.payload, this.state.selectedStyleId)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                </div> : null}
                {/* END VIDEO POPUP */}

                {this.state.pdfPopup ? <div className="PopupBk"></div> : null}
                {this.state.pdfPopup ? <div className="uploadPopup">
                    <button className="popupClose" onClick={this.pdfPopClick}>X</button>
                    <div className="popupShowFile">
                        <iframe width="560" height="315" src={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf} title={TypicalVillasUtils.getVillaTitle(this.state.lookupsPayload, this.context.state.siteLang)} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                    </div>
                </div> : null}

                <div className="container mainSection">
                    <div className="row">
                        {/* breadcrumb */}
                        <div className="col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/customerDashboard">{translate('dashboard-lbl')}</a></li>
                                    <li className="breadcrumb-item"><a href="/typicalVillas">{translate('typicalvillas')}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{translate('villadetails')}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_title_wrapper">
                                <span>{translate('villadetails')}</span>
                            </div>
                        </div>
                    </div>

                    <div className="service_contant_wrapper formSection typicalVillasDetailWrapper">
                        <div className="service_title_wrapper">
                            <h3>{translate('servicesteps')}</h3>
                        </div>
                        <div className='stagesWrapper'>
                            <div className='stagesBox stagesBg1'>
                                <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco1.svg'} alt="Apply for request" /></div>
                                <p>{translate('applyforrequest')}</p>
                            </div>
                            <div className='stagesBox stagesBg2'>
                                <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco2.svg'} alt="Request under process" /></div>
                                <p>{translate('requestunderprocess')}</p>
                            </div>
                            <div className='stagesBox stagesBg3'>
                                <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco3.svg'} alt="Amount payment" /></div>
                                <p>{translate('amountpayment')}</p>
                            </div>
                            <div className='stagesBox stagesBg4'>
                                <div className='boxIco'><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/stagesBoxIco4.svg'} alt="Establishment step" /></div>
                                <p>{translate('establishmentstep')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="service_contant_wrapper formSection typicalVillasDetailWrapper">
                                <div className='row'>
                                    <div className='col-md-12'>
                                        {/* {this.state.payload ? <div className='CarouselWrapper'>
                                            {this.state.selectedStyleId === '0'
                                                ? TypicalVillasUtils.getImage(this.state.payload.id, this.state.payload.styles[0].image, 0)
                                                : TypicalVillasUtils.getStyleImageURLArray(this.state.selectedStyleId, this.state.payload)
                                            }
                                        </div> : null} */}
                                        <div className='CarouselWrapper'>
                                            {this.state.payload ? TypicalVillasUtils.getImageURLArray(this.state.payload, this.state.lookupsPayload, this.context.state.siteLang) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className='row iconsWrapper'>
                                    <div className='col'>
                                        <ul>
                                            <li>
                                                <button onClick={this.faqPopClick}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/aqs_icon.svg'} alt="AQs" /></i>
                                                    <span>{translate("faq")}</span>
                                                </button>
                                            </li>
                                            {/* <li>
                                                <button onClick={this.videoPopClick}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/video_icon.svg'} alt="video" /></i>
                                                    <span>{translate("ModelVideo")}</span>
                                                </button>
                                            </li> */}
                                            {/* <li>
                                                <a href={this.state.payload.model3D ? this.state.payload.model3D : 'https://www.google.com/'} target="_blank" rel="noopener noreferrer">
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/3d_icon.svg'} alt="3DView" /></i>
                                                    <span>{translate("3DView")}</span>
                                                </a>
                                            </li> */}
                                            <li>
                                                <a href="#!">
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/share_icon.svg'} alt="share" /></i>
                                                    <span>{translate("share")}</span>
                                                </a>
                                                <ul>
                                                    <li>
                                                        <FacebookShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf} >
                                                            <FacebookIcon size={30} round={true} />
                                                        </FacebookShareButton >

                                                        <TwitterShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <TwitterIcon size={30} round={true} />
                                                        </TwitterShareButton>

                                                        <EmailShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <EmailIcon size={30} round={true} />
                                                        </EmailShareButton>

                                                        <WhatsappShareButton url={CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf}>
                                                            <WhatsappIcon size={30} round={true} />
                                                        </WhatsappShareButton>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <button onClick={() => window.open(CONSTANTS.unifiedVillasPdfURL + this.state.payload.pdf, '_blank', 'noopener,noreferrer', "PRINT", "height=400,width=600")}>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/b_print_icon.svg'} alt="Print" /></i>
                                                    <span>{translate("Print")}</span>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='row houseDetails'>
                                    <div className="col-md-6 largFonts">
                                        <h2>{TypicalVillasUtils.getVillaTitle(this.state.lookupsPayload, this.context.state.siteLang)} {this.state.payload.title}</h2>
                                        <ul>
                                            <li>
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/house_new.svg'} alt="img" /></i>
                                                <span>{translate('floors')} : {this.state.payload.floor}</span>
                                            </li>
                                            <li>
                                                <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/bed.svg'} alt="img" /></i>
                                                <span>{translate('bedrooms')} : {this.state.payload.bedroom}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-6 largFonts">
                                        <h2>{this.state.payload ? TypicalVillasUtils.getStyleLabel(this.state.lookupsPayload, this.state.payload, this.state.selectedStyleId, this.context.state.siteLang) : ''}</h2>
                                        <ul>
                                            <li>
                                                <span>{translate('area')} : {this.state.payload ? TypicalVillasUtils.getStyleArea(this.state.payload, this.state.selectedStyleId) : 0} {translate('sqft')}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 largFonts">
                                        <ul>
                                            <li>
                                                <span>{translate("startingprice")} : {this.state.payload.startingPrice} {translate("dirham")}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-md-12 typicalTitle'>
                                        <h2>{translate("consultantInformation")}</h2>
                                    </div>
                                    <div className='col-md-12 typicalInfoWrapper'>
                                        <hr></hr>
                                        <ul>
                                            <li>
                                                <div className='typicalInfoBox'>
                                                    <span>{translate("consultantcompany")}</span>
                                                    {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.name : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                                </div>
                                            </li>
                                            <li>
                                                <span>{translate("engineername")}</span>
                                                {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.eng_name : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("email")}</span>
                                                {this.state.consultant ? <span> {this.state.consultant ? this.state.consultant.email : ''}</span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("mobileNo")}</span>
                                                {this.state.consultant ? <span>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/call_icon.svg'} alt="call" /></i>
                                                    <a href='tel:0501000000'>{this.state.consultant ? this.state.consultant.contactNo : ''}</a>
                                                </span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                            <li>
                                                <span>{translate("Locationonthemap")}</span>
                                                {this.state.consultant ? <span>
                                                    <i><img src={'https://mbrhe-cdn.azureedge.net/eservices-portal/assets/images/location_icon.svg'} alt="location" /></i>
                                                    <a href={this.state.consultant ? this.state.consultant.location : ''} target="_blank" rel="noopener noreferrer">{translate("clickhere")}</a>
                                                </span> : <span>{translate("nodataavaialble")}</span>}
                                            </li>
                                        </ul>
                                    </div>
                                    {this.state.canApplayTypicalVilla === 'true' && (this.state.requestStatus === '10' || this.state.requestStatus === '') ? <div className="houseBtns center">
                                        <button className="primary_button button_50" onClick={this.goNext} href="">{translate('applyforservice')}</button>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AuthenticatedComponent(TypicalVillasDetailComponent)